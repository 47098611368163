"use client";

import { useAuth } from "@/state/AuthContexts";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import { Loader } from "../shared/components";

export default function Home() {
  const router = useRouter();
  const pathname = usePathname();

  const { user, company } = useAuth();

  console.log("Home page");

  useEffect(() => {
    window && window.Intercom && window.Intercom("update");

    // If they are navigating to the root page, lets redirect.
    if (pathname === "/") {
      // If they are a user, lets..
      if (user && company) {
        // See if theyre onboarded:
        if (
          company?.OnboardingStatus?.onboarded &&
          company?.expected_activity
        ) {
          // If they're a buyer:
          if (company.expected_activity === "buyer") {
            router.push("/bills");
          } else {
            router.push("/invoices");
          }
          // Otherwise lets take them to setup!
        } else {
          router.push("/setup");
        }
      }
      // Not navigating to the root page? They must be just reloading the page
    } else {
      // Lets check if their reload is to check if they're verification step happened
      if (user && user.verified && pathname === "/signup/verify") {
        router.push("/setup");
      }
      // else, just reload the page
      router.push(pathname || "/");
    }
  }, [user, company?.OnboardingStatus?.onboarded, company, pathname, router]);

  return (
    <>
      <div className="h-screen" data-testid="home">
        <Loader size="10" label="Redirecting..." />
      </div>
    </>
  );
}
